import { useMemo } from "react";

import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image, ImageProps } from "@chakra-ui/react";

import { ReactComponent as ReleaseAiLogo } from "@/assets/images/logos/ai-logo.svg";
import { ReactComponent as InstantDatasetsLogo } from "@/assets/images/logos/instant-datasets-logo.svg";
import { ReactComponent as ReleaseLogo } from "@/assets/images/logos/release-logo.svg";
import { Product } from "@/generated/graphql";
import { useWorkspace } from "@/providers/useWorkspace";

export default function Logo({ product: propsProduct, ...props }: { product?: Product } & ImageProps) {
  const { product: workspaceProduct } = useWorkspace();
  const fillColor = useColorModeValue("brand.700", "gray.100");

  const product = propsProduct ?? workspaceProduct;

  const logoComponent = useMemo(() => {
    switch (product) {
      case Product.ReleaseAi: {
        return (
          <Image
            alt="Release AI Logo"
            as={ReleaseAiLogo}
            fill={fillColor}
            overflow="visible"
            width="auto"
            {...props}
          />
        );
      }
      case Product.StandaloneDatasets: {
        return (
          <Image
            alt="Release Instant Datasets Logo"
            as={InstantDatasetsLogo}
            fill={fillColor}
            width="auto"
            {...props}
          />
        );
      }
      case Product.Release:
      default:
        return <Image alt="Release Logo" as={ReleaseLogo} fill={fillColor} width="auto" {...props} />;
    }
  }, [product, fillColor, props]);

  return logoComponent;
}
