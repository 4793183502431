/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-multi-comp */

import { useCallback, useEffect, useMemo, useState } from "react";
import { PropsWithChildren } from "react";

import { gql } from "@apollo/client";
import { useRouter } from "next/router";

import {
  Product,
  useWorkspaceProvider__WorkspacesQuery,
  WorkspaceProvider__WorkspaceFragment,
} from "@/generated/graphql";
import createProvider from "@/providerFactories/createProvider";

type WorkspaceProviderType = {
  currentWorkspace?: WorkspaceProvider__WorkspaceFragment;
  product?: Product;
  workspaceId: string;
  workspaces?: WorkspaceProvider__WorkspaceFragment[];
};

export const initialData: WorkspaceProviderType = {
  workspaceId: "",
  workspaces: [],
};

const useWorkspaceProviderHook = () => {
  const router = useRouter();
  const { workspaceId } = router.query;
  const workspaceIdValue = Array.isArray(workspaceId) ? workspaceId[0] : workspaceId;
  const [workspace, setWorkspace] = useState<WorkspaceProvider__WorkspaceFragment>();

  const currentProduct = useMemo(() => {
    if (router.asPath.includes("instantdatasets")) {
      return Product.StandaloneDatasets;
    } else if (router.asPath.includes("/ai/")) {
      return Product.ReleaseAi;
    } else if (workspace?.products) {
      return workspace?.products.includes(Product.Release) ? Product.Release : Product.StandaloneDatasets;
    } else {
      return Product.Release;
    }
  }, [router, workspace]);

  const { data, called, loading } = useWorkspaceProvider__WorkspacesQuery();
  const workspaces = useMemo(() => data?.workspaces?.nodes, [data?.workspaces?.nodes]);

  const onWorkspaceChange = useCallback(() => {
    if (workspaces) {
      const currentWorkspace =
        workspaceIdValue &&
        workspaces?.find(
          workspace =>
            workspace.databaseId === workspaceIdValue || workspace.unhashedDatabaseId === workspaceIdValue
        );

      if (!currentWorkspace) {
        setWorkspace(workspaces[0]);
        router.push(
          `/workspaces/${workspaces[0].databaseId}/${
            currentProduct === Product.StandaloneDatasets ? "instantdatasets/datasets" : "applications"
          }`
        );
      } else {
        setWorkspace(currentWorkspace);
      }
    }
  }, [currentProduct, router, workspaceIdValue, workspaces]);

  const { databaseId } = workspace || {};

  useEffect(() => {
    if (router.isReady && called && !loading) onWorkspaceChange();
  }, [router.isReady, called, loading, onWorkspaceChange]);

  return {
    workspaceId: databaseId || workspaceIdValue || "",
    workspaces,
    currentWorkspace: workspace,
    product: currentProduct,
  };
};

gql`
  query WorkspaceProvider__Workspaces {
    workspaces {
      nodes {
        ...WorkspaceProvider__Workspace
      }
    }
  }

  fragment WorkspaceProvider__Workspace on Workspace {
    id
    avatarUrl
    databaseId
    name
    unhashedDatabaseId
    products
    authenticationToken
  }
`;

export const [useWorkspace, WorkspaceProvider, WorkspaceConsumer] = createProvider<
  WorkspaceProviderType,
  PropsWithChildren<unknown>
>(initialData, useWorkspaceProviderHook);
